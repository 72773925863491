import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import { CourseModel } from '../../models';

import CoursesListItem from './CoursesListItem';

interface CoursesListProps {
    courses: CourseModel[];
}

const CoursesList: React.FC<CoursesListProps> = ({ courses }) => {
    const [activeIndex, setActiveIndex] = useState(-1);

    const onMouseOver = (i) => () => setActiveIndex(i);
    const onMouseLeave = () => () => setActiveIndex(-1);

    useEffect(() => {
        const resetActive = () => setActiveIndex(-1);

        window?.addEventListener('scroll', resetActive, { passive: true });

        return () => window?.removeEventListener('scroll', resetActive);
    }, []);

    return (
        <CoursesContainer>
            {courses.map((item, index) => (
                <CoursesListItem
                    onMouseOver={onMouseOver(index)}
                    onMouseLeave={onMouseLeave()}
                    key={item.title}
                    item={item}
                    opacity={activeIndex === index || activeIndex < 0 ? 1 : 0.5}
                />
            ))}
        </CoursesContainer>
    );
};

const CoursesContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 36px;
`;

export default CoursesList;
