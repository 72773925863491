import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import PageLayout from '../components/PageLayout';
import { ContentMain, RichTextView } from '../components/commonStyled';
import Sections from '../components/Sections';
import { useCourses } from '../queries/useCourses';
import CoursesList from '../components/CoursesList/CoursesList';

export default function posts({ data, location }) {
    const {
        title,
        seo,
        content,
        common_acf: { blocks },
    } = data?.wpPage;

    const courses = useCourses();

    return (
        <PageLayout
            breadcrumbs={[
                {
                    url: location?.pathname,
                    title,
                },
            ]}
            title={title}
            seoMeta={seo}
        >
            <ContentMain>
                <TextContainer>
                    <RichTextView
                        fontSize={18}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </TextContainer>
                <CoursesList courses={courses} />
            </ContentMain>
            <Sections
                enabledSections={blocks}
                options={{
                    actionBlockMiddle: { whiteStyle: true, noButton: true },
                }}
            />
        </PageLayout>
    );
}

const TextContainer = styled.div`
    padding-bottom: 24px;
`;

export const pageQuery = graphql`
    query Obuchenie {
        wpPage(common_acf: { id: { eq: "courses" } }) {
            title
            content
            seo {
                ...Seo
            }
            common_acf {
                blocks
            }
        }
    }
`;
