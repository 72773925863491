import React from 'react';
import styled from '@emotion/styled';

import { CourseModel } from '../../models';
import CourseCard from '../CourseCard';
import { RoundedButtonWithArrow, device } from '../commonStyled';
import { FONT_GOTHAM_LIGHT, FONT_GOTHAM_MEDIUM } from '../../styles/fonts';

interface CoursesListItemProps {
    item: CourseModel;
    opacity: number;
    onMouseLeave?(): void;
    onMouseOver?(): void;
}

const CoursesListItem: React.FC<CoursesListItemProps> = ({
    item: { description, start_date, title, url, image, subtitle },
    opacity,
    onMouseLeave,
    onMouseOver,
}) => {
    return (
        <CourseListItemContainer
            opacity={opacity}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
        >
            <CourseCardContainer>
                <CourseCard
                    image={image}
                    subTitle={subtitle}
                    title={title}
                    fontSize={24}
                />
            </CourseCardContainer>
            <InfoContainer>
                <DateTitle>
                    Старт <DateStyled>{start_date}</DateStyled>
                </DateTitle>
                <Description
                    dangerouslySetInnerHTML={{ __html: description }}
                />
                <ButtonContainer href={url} rel="nofollow">
                    <ButtonInfo>Посмотреть программу и</ButtonInfo>
                    <RoundedButtonWithArrow>Записаться</RoundedButtonWithArrow>
                </ButtonContainer>
            </InfoContainer>
        </CourseListItemContainer>
    );
};

const CourseCardContainer = styled.div`
    flex-shrink: 0;
    width: 344px;

    @media ${device.tabletMedium} {
        margin-bottom: 36px;
    }
`;

const ButtonInfo = styled.div`
    color: #a60424;
    font-family: ${FONT_GOTHAM_LIGHT};
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 8px;
`;

const ButtonContainer = styled.a`
    display: block;
`;

const Description = styled.div`
    display: flex;
    align-items: center;
    color: #2e0f25;
    font-size: 18px;
    line-height: 26px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    flex-grow: 1;
    margin: 24px 0;

    @media ${device.tabletMedium} {
        text-align: center;
    }
`;

const DateTitle = styled.div`
    color: #000000;
    font-family: GothamPro;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    font-family: ${FONT_GOTHAM_MEDIUM};
`;

const DateStyled = styled.span`
    color: #a60424;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 65px;

    @media ${device.tabletMedium} {
        align-items: center;
        margin-left: 0;
    }
`;

const CourseListItemContainer = styled('div')<{ opacity: number }>`
    width: 100%;
    display: flex;
    padding-top: 42px;
    padding-bottom: 42px;
    border-top: 1px solid rgba(209, 209, 209, 0.5);
    opacity: ${({ opacity }) => opacity};
    transition: all 0.5s ease-in-out;

    @media ${device.tabletMedium} {
        flex-direction: column;
        align-items: center;
        padding-bottom: 42px;
    }
`;

export default CoursesListItem;
